<script>
  import { getAuth } from "../auth";
  import Account from "../components/Account.svelte";
  import OpenTickets from "../components/OpenTickets.svelte";
  import BlocketTickets from "../components/BlockerTickets.svelte";
  import Last10Tickets from "../components/Last10Tickets.svelte";
  import Trainings from "../components/Trainings.svelte";
  import Notes from "../components/Notes.svelte";

  export let id;

  const {
    isLoading,
    isAuthenticated,
    login,
    logout,
    authToken,
    jwtToken,
    authError,
    userInfo
  } = getAuth();

  let promise = getAccount(id);

  async function getAccount(account_number) {
    let response = await fetch(`api/account/${account_number}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + $jwtToken
      }
    });

    if (response.ok) {
      let json = await response.json();
      return json;
    } else {
      let text = await response.text();
      throw new Error(text);
    }
  }
</script>

{#await promise}
  <div class="d-flex justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
{:then response}
  <Account account={response.account} tap={response.tap} />
  <div class="row">
    <div class="col">
      <Notes notes={response.account.notes} accountId={response.account.id} />
      <OpenTickets tickets={response.openTickets} />
      <BlocketTickets tickets={response.blockerTickets} />
      <Trainings trainings={response.trainings} />
    </div>
    <div class="col">
      <Last10Tickets tickets={response.last10tickets} />
    </div>
  </div>
{:catch error}
  <p style="color: red">{error.message}</p>
{/await}
