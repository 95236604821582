<script>
  export let trainings;
</script>

<div class="shadow-sm p-3 mb-5 bg-white rounded">
  <h5>Last trainings</h5>
  {#if trainings.length == 0}
    <div class="nothing">
      <h6>No trainings</h6>
    </div>
  {:else}
    <table class="table table-hover">
      <tbody>
        {#each trainings as training}
          <tr>
            <td>
              <small>
                <a
                  href="https://sonarsource.lightning.force.com/lightning/r/Case/{training.id}/view"
                  target="_blank">
                  {training.subject}
                </a>
              </small>
            </td>
            <td class="align-middle">
              <small>{training.type}</small>
            </td>
            <td class="align-middle">
              <small>{training.status}</small>
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  {/if}
</div>
