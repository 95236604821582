<script>
  import { onMount } from "svelte";
  import { Router, Route } from "yrv";
  import Home from "./routes/Home.svelte";
  import Customer from "./routes/Customer.svelte";
  import { createAuth } from "./auth";

  // Go to Auth0 to get the values and set everything up.
  // Make sure all callback urls are set correctly.
  const config = {
    domain: "sed-staging.eu.auth0.com",
    client_id: "qnghNfXPEBoS7aRj9KCMV9OmnPcTGHZp"
  };

  const {
    isLoading,
    isAuthenticated,
    login,
    logout,
    authToken,
    authError,
    userInfo
  } = createAuth(config);

  $: disabled = !$isAuthenticated;
</script>

<style>
  .card {
    width: 100%;
    max-width: 330px;
    margin: auto;
    margin-top: 50px;
  }
</style>

<div class="container">
  {#if $isLoading}
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  {:else if $authError}
    <p>Got error: {$authError.message}</p>
  {:else if !$isAuthenticated}
    <div class="card">
      <img class="card-img-top" src="favicon.png" alt="SED" />
      <div class="card-body">
        <button
          class="btn btn-lg btn-primary btn-block"
          type="submit"
          on:click={() => login()}>
          Login
        </button>
      </div>
    </div>
  {:else}
    <Router {disabled}>
      <Route exact>
        <Home />
      </Route>
      <Route exact path="/:id" let:router>
        <Customer id={router.params.id} />
      </Route>
    </Router>
  {/if}
</div>
