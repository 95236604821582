<script>
  export let notes;
  export let accountId;
</script>

<div class="shadow-sm p-3 mb-5 bg-white rounded">
  <a class="float-right" href="https://sonarsource.lightning.force.com/lightning/r/Account/{accountId}/edit" target="_blank">edit</a>
  <h5>Notes</h5>
  {#if !notes || notes.length == 0}
    <div class="nothing">
      <h6>N/A</h6>
    </div>
  {:else}
    {@html notes}
  {/if}
</div>
