<script>
  export let account, tap;

  import SvelteTooltip from "svelte-tooltip";
</script>

<div class="row sticky-top shadow-sm p-3 mb-5 bg-white rounded">
  <div class="col-sm">
    <a
      class="h3"
      href="https://sonarsource.lightning.force.com/lightning/r/Account/{account.id}/view"
      target="_blank">
      {account.name}
    </a>
    <p>{account.account_number}</p>
  </div>
  <div class="col-sm text-center">
    {#if account.assets}
      <ul class="list-unstyled">
        <li>
          <SvelteTooltip tip="Account owner" bottom>
            <a href="mailto:{account.owner.email}" target="_blank">
              {account.owner.name}
            </a>
          </SvelteTooltip>
        </li>
        {#each account.assets as asset}
          <li>
            <span class="badge badge-secondary">{asset}</span>
          </li>
        {/each}
      </ul>
    {/if}
    {#if tap}
      <a href="https://sonarsource.lightning.force.com/lightning/r/Case/{tap}/view">
        Ongoing TAP
      </a>
    {/if}
  </div>
  <div class="col-sm">
    <ul class="list-unstyled float-md-right float-lg-right float-xl-right">
      {#if account.service_desk_contact}
        <li>
          <SvelteTooltip tip="Service Desk contact" bottom>
            <a
              href="mailto:{account.service_desk_contact.email}"
              target="_blank">
              {account.service_desk_contact.name}
            </a>
          </SvelteTooltip>
        </li>
      {/if}
      {#if account.technical_contact}
        <li>
          <SvelteTooltip tip="Technical contact" bottom>
            <a href="mailto:{account.technical_contact.email}" target="_blank">
              {account.technical_contact.name}
            </a>
          </SvelteTooltip>
        </li>
      {/if}
    </ul>
  </div>
</div>
