<script>
  import { getAuth } from "../auth";
  import { Link } from "yrv";

  const {
    isLoading,
    isAuthenticated,
    login,
    logout,
    authToken,
    jwtToken,
    authError,
    userInfo
  } = getAuth();
</script>

<style>
  img {
    padding-top: 20px;
    width: 100%;
  }

  .align-middle {
    align-items: center;
    display: flex;
  }
</style>

<div class="row sticky-top shadow-sm p-3 mb-5 bg-white rounded">
  <div class="col-sm-2">
    <button class="btn btn-primary btn-block" on:click={() => logout()}>
      Logout
    </button>
  </div>
  <div class="col-sm-8 align-middle">
    <small>&lt;-- In case you really need this ¯\_(ツ)_/¯</small>
  </div>
</div>

<div class="jumbotron">
  <h1 class="display-4">Welcome on the Support Engineer Dashboard!</h1>
  <p class="lead">The place to have all informations about a customer.</p>
  <hr class="my-4" />

  <p>
    Don't know how to use SED?
    <br />
    Try to put an account number in the address bar ;)
    <img src="/how_to_use_sed.gif" alt="how to use sed" />
  </p>

</div>
