<script>
  export let tickets;

  import moment from "moment";
  import SvelteTooltip from "svelte-tooltip";
</script>

<style>
  .priority {
    height: 16px;
  }
</style>

<div class="shadow-sm p-3 mb-5 bg-white rounded">
  <h5>Blocker tickets in the last 6 months</h5>
  {#if tickets.length == 0}
    <div class="nothing">
      <h6>No blocker tickets</h6>
    </div>
  {:else}
    <table class="table table-hover">
      <tbody>
        {#each tickets as ticket}
          <tr>
            <td>
              <small>
                <a
                  href="https://services.sonarsource.com/browse/{ticket.key}"
                  target="_blank">
                  {ticket.title}
                </a>
              </small>
            </td>
            <td class="align-middle">
              <SvelteTooltip tip={ticket.priority.name} bottom>
                <img
                  class="priority"
                  src={ticket.priority.iconUrl}
                  alt={ticket.priority.name} />
              </SvelteTooltip>
            </td>
            <td class="align-middle">
              <SvelteTooltip tip={ticket.status.name} bottom>
                <img src={ticket.status.iconUrl} alt={ticket.status.name} />
              </SvelteTooltip>
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  {/if}
</div>
