<script>
  export let tickets;

  import moment from "moment";
</script>

<div class="shadow-sm p-3 mb-5 bg-white rounded">
  <h5>Last 10 tickets resolved</h5>
  {#if tickets.length == 0}
    <div class="nothing">
      <h6>No tickets resolved</h6>
    </div>
  {:else}
    <table class="table table-hover">
      <tbody>
        {#each tickets as ticket}
          <tr>
            <td>
              <small>
                <a
                  href="https://services.sonarsource.com/browse/{ticket.key}"
                  target="_blank">
                  {ticket.title}
                </a>
              </small>
            </td>
            <td class="align-middle">
              <ul class="nav">
                {#each ticket.components as component}
                  <li>
                    <span class="badge badge-secondary">{component}</span>
                  </li>
                {/each}
              </ul>
            </td>
            <td class="align-middle">
              <small>
                {moment(ticket.resolutionDate).format('DD/MM/YYYY')}
              </small>
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  {/if}
</div>
